const pricing = [
	{
		id: 'cpanel-vps-1',
		title: 'VPS 1',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['cPanel/WHM - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '2GB Guaranteed RAM', '60GB NVMe SSD Storage', '3TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-2',
		title: 'VPS 2',
		price: '$34.95 /mo',
		banner: 'AWS',
		features: ['cPanel/WHM - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '4GB Guaranteed RAM', '80GB NVMe SSD Storage', '4TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-3',
		title: 'VPS 3',
		price: '$54.95 /mo',
		banner: 'AWS',
		features: ['cPanel/WHM - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '8GB Guaranteed RAM', '160GB NVMe SSD Storage', '5TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'cpanel-vps-4',
		title: 'VPS 4',
		price: '$94.95 /mo',
		banner: 'AWS',
		features: ['cPanel/WHM - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '4 Cores Intel® Xeon® CPU', '16GB Guaranteed RAM', '320GB NVMe SSD Storage', '6TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-1',
		title: 'VPS 1',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['Plesk - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '2GB Guaranteed RAM', '60GB NVMe SSD Storage', '3TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-2',
		title: 'VPS 2',
		price: '$34.95 /mo',
		banner: 'AWS',
		features: ['Plesk - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '4GB Guaranteed RAM', '80GB NVMe SSD Storage', '4TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-3',
		title: 'VPS 3',
		price: '$54.95 /mo',
		banner: 'AWS',
		features: ['Plesk - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '8GB Guaranteed RAM', '160GB NVMe SSD Storage', '5TB Premium Bandwidth',  'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'plesk-vps-4',
		title: 'VPS 4',
		price: '$94.95 /mo',
		banner: 'AWS',
		features: ['Plesk - 14-Day Trial License', 'Server Setup', 'White Label', '1 Amazon IPv4', '4 Cores Intel® Xeon® CPU', '16GB Guaranteed RAM', '320GB NVMe SSD Storage', '6TB Premium Bandwidth', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'docker-vps-1',
		title: 'VPS 1',
		price: '$22.95 /mo',
		banner: 'AWS',
		features: ['WordPress with Yoast SEO', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '1GB Guaranteed RAM', '40GB NVMe SSD Storage', '2TB Premium Bandwidth', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'docker-vps-2',
		title: 'VPS 2',
		price: '$34.95 /mo',
		banner: 'AWS',
		features: ['WordPress with Yoast SEO', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '2GB Guaranteed RAM', '60GB NVMe SSD Storage', '3TB Premium Bandwidth', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'docker-vps-3',
		title: 'VPS 3',
		price: '$54.95 /mo',
		banner: 'AWS',
		features: ['WordPress with Yoast SEO', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '4GB Guaranteed RAM', '80GB NVMe SSD Storage', '4TB Premium Bandwidth', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
	{
		id: 'docker-vps-4',
		title: 'VPS 4',
		price: '$94.95 /mo',
		banner: 'FBH',
		features: ['WordPress with Yoast SEO', 'Server Setup', 'White Label', '1 Amazon IPv4', '2 Cores Intel® Xeon® CPU', '8GB Guaranteed RAM', '160GB NVMe SSD Storage', '5TB Premium Bandwidth', 'Full Root Access', 'Full SSH Access', 'Secure AWS Data Center', '24/7 Support', '24/7 Monitoring'],
		rrp: 'FULLY-MANAGED',
	},
];

export default pricing;
